export enum LivePageWidgetType {
  MAP = 'MAP',
  VIDEO = 'VIDEO',
}

export enum DrawingOption {
  ALERT = 'ALERT',
  BUILDING = 'BUILDING',
  NO_FLY = 'NO_FLY',
  POI = 'POI',
  GEO_FENCE = 'GEO_FENCE',
  BASE = 'BASE',
  ELZ = 'ELZ',
  WAYPOINT = 'WAYPOINT',
  NULL = 'null',
}

export enum MapFeatureState {
  NEW = 'new',
  UPDATE = 'update',
  NULL = 'null',
}

export enum SocketDrawingOption {
  ICON = 'ICON',
  DIVICON = 'DIVICON',
  CIRCLEMARKER = 'CIRCLEMARKER',
}

export enum UnitSystemOption {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
}

export enum TempUnitOption {
  FAHRENHEIT = 'FAHRENHEIT',
  CELSIUS = 'CELSIUS',
}

export enum SpeedUnitOption {
  KPH = 'KPH',
  MPH = 'MPH',
}

export enum VideoPlayerOption {
  WEBRTC = 'WEBRTC',
  HLS = 'HLS',
}

export enum VideoStreamOption {
  THERMAL = 'THERMAL',
  RGB = 'RGB',
}

export enum VideoDefinition {
  HD = 'HD',
  SD = 'SD',
}

interface UserRole {
  level: number;
  title: string;
  protected?: boolean;
  created?: boolean;
}

export const UserRoles: UserRole[] = [
  {
    title: 'Robot',
    level: 0,
  },
  {
    title: 'Wingman',
    level: 1,
  },
  {
    title: 'Pilot',
    level: 2,
  },
  {
    title: 'Captain',
    level: 3,
  },
  {
    title: 'Admin',
    level: 4,
  },
  {
    title: 'SuperUser',
    level: 9001,
  },
];

export enum DroneCommands {
  RTB = 'RTB',
  ELZ = 'ELZ',
  GAZE = 'GAZE',
  FLY_TO = 'FLY_TO',
  ORBIT = 'ORBIT',
}

export enum Direction {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  UP = 'UP',
  DOWN = 'DOWN',
}

export const TOAST_DEFAULT_DURATION = 5000; // ms

export const TOAST_WARNING_DURATION = 10000; // ms

export const VIDEO_OFFSET_DURATION = 8000;

export const WEEKDAY_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const WEEKDAY_FULL = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const RGB_ZOOM_LEVELS: { label: string; value: number }[] = [
  {
    label: '1x',
    value: 0,
  },
  {
    label: '2x',
    value: 1,
  },
  {
    label: '3x',
    value: 2,
  },
  {
    label: '4x',
    value: 3,
  },
  {
    label: '6x',
    value: 4,
  },
];

export const THERMAL_ZOOM_LEVELS: { label: string; value: number }[] = [
  {
    label: '1x',
    value: 0,
  },
  {
    label: '2x',
    value: 1,
  },
  {
    label: '3x',
    value: 2,
  },
  {
    label: '4x',
    value: 3,
  },
];

export enum HeadingDirectionsShort {
  NORTH = 'N',
  NORTHEAST = 'NE',
  EAST = 'E',
  SOUTHEAST = 'SE',
  SOUTH = 'S',
  SOUTHWEST = 'SW',
  WEST = 'W',
  NORTHWEST = 'NW',
}
// mph
export const WAYPOINT_SPEED_SLOW_MIN_IMPERIAL = 1;
export const WAYPOINT_SPEED_FAST_MAX_IMPERIAL = 35;

// kph
export const WAYPOINT_SPEED_SLOW_MIN_METRIC = 1.6;
export const WAYPOINT_SPEED_FAST_MAX_METRIC = 56.3;

export const DURATION_MAX_SECONDS = 7200;
export const MAX_ALTITUDE = 99999;

export const ELZ_CIRCLE_RADIUS = 10;

export const ORBIT_CIRCLE_RADIUS = 30;

export const PASSWORDCONFIG: any = [
  {
    id: 0,
    value: 'Too Weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Too Weak',
    minDiversity: 0,
    minLength: 4,
  },
  {
    id: 2,
    value: 'Weak',
    minDiversity: 1,
    minLength: 6,
  },
  {
    id: 3,
    value: 'Medium',
    minDiversity: 2,
    minLength: 8,
  },
  {
    id: 4,
    value: 'Good',
    minDiversity: 3,
    minLength: 10,
  },
  {
    id: 5,
    value: 'Strong',
    minDiversity: 4,
    minLength: 12,
  },
];

export const BVLOS_TRIGGER_DISTANCE_METERS = 5000; // 5km in meters
export const BVLOS_TRIGGER_ALTITUDE_AGL = 500; // 500 meters currently in MSL because of data constraints, refer to asana task
export const LOW_BATTERY_THRESHHOLD = 20;

export const MAP_WIDGET_SIZES = {
  normal: {
    width: 512,
    height: 256,
  },
  expanded: {
    width: 768,
    height: 384,
  },
};

export const VIDEO_WIDGET_SIZES = {
  normal: {
    width: 455,
    height: 256,
  },
  expanded: {
    width: 683,
    height: 384,
  },
};
